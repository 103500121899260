import restaurant from "./images/paradis.jpg";
import biserica from "./images/bis.jpg";
import img_card from "./images/fam.jpg";
import imgOmSuflet from "./images/family.jpg";
import imgOmSufletmb from "./images/family.jpg";
import imgheader from "./images/buj.avif";
import img2 from "./images/6n.jpg";
import imgheadermiini from "./images/wedding-header.jpg";
import imgheadermiinimb from "./images/wedding-header.jpg";
import imgconfirmare from "./images/11.jpg";



const data = {
    introData: [{
        familia: "",
        mire: "Olga",
        mireasa: "Constantin",
        copil: 'Letiția',
        data: "26 August 2023",
        data_confirmare: "20 iulie 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "constantin.botezatu28.cb@gmail.com", 
       tel: "+373 69 283 307 ",
       phone: "tel:+37369283307",
       viber: "viber://chat?number=%2B37369283307",
       whatsapp: "https://wa.me/+37369283307",
       messenger: "https://www.messenger.com/t/",
       tel1: "+40770216776",
       phone1: "tel:+40770216776",
       viber1: "viber://chat?number=%2B40770216776",
       whatsapp1: "https://wa.me/+40770216776",
       messenger1: "https://www.messenger.com/t/daniel.dnl.5201",
    }],
    cardData: [
       
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "Restaurantul",
            name: "Paradis",
            data: "26 august 2023, ora 18:00",
            ora: "",
            adress: "Strada Independenței 31 a, Edineţ",
            harta: "https://goo.gl/maps/4Ef4aRTmYWHijFWi6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10642.931975064925!2d27.3026367!3d48.1732271!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47335df332a0b8ef%3A0x3f17b97eb7305c77!2sParadis%20Restaurant!5e0!3m2!1sro!2s!4v1685548794370!5m2!1sro!2s" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "Cununie și botez!",
            mami: "",
            tati: "La 2 în 1 vă poftim!",
            message: "Foaie verde de trifoi,     ",
            message1: "Super promoție la noi:",
            message2: "Chiar așa! Ce, nu ne crezi?",
            message3: "Hai să vezi că nu glumim.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Vă invităm la celebrarea căsătoriei noastre!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celor mai semnificative evenimente din viața noastră.",
            message1: "P.S.: Vom fi recunoscători dacă tradiţionalul buchet de flori va fi înlocuit cu o carte sau o sticlă de vin bun",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Serghei și Ala ",
            parintii_nume2: "Maria",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Florin şi Ana-Maria",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Ținem la voi și ne-am bucura enorm dacă ați fi alături de noi într-o zi atât de specială!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;