import React from "react";

import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";

import FavoriteIcon from '@mui/icons-material/Favorite';

import { red } from "@mui/material/colors";

import CardLocal from "../cardLocal";

import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import Slide from "./Slide";
import MyComponent from "./Animation";
import flowers from '../images/flowers.gif'


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="700px" opacity=".5" >
                        <div id="noi" className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12" style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 style={{ fontSize: '60px' }}>{item.mireasa} & {item.mire}  <br/>și </h1>
                                <h1 style={{ fontSize: '60px' }}>{item.copil} </h1>
                                <h4 style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}
<div className="text-center pt-5">
    <h4 >
    Cum să trăieşti frumos fără iubire? <br />
                                    Cum să găseşti în viaţă fericirea?<br />
                                    Cum să păşeşti prin beznă şi prin ploi?<br />
                                    Cum altfel, decât amândoi!
    </h4>

</div>


            {/**  <BlogSectionNoi /> 

            
            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="500px"  opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 style={{ fontSize: '35px' }}>
                                    Cum să trăieşti frumos fără iubire? <br />
                                    Cum să găseşti în viaţă fericirea?<br />
                                    Cum să păşeşti prin beznă şi prin ploi?<br />
                                    Cum altfel, decât amândoi!

                                </h1>

                            </div>
                        </div>
                    </Parallaximg>
                )
            })}*/}

<BlogSectionNasii />



            {/**   <Slide/>*/}

{data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="650px" opacity=".2">
                       <div className="row text-center align-items-center mx-lg-5" style={{ position: 'absolute' }}>
                            <div className="col-12" style={{ maxWidth: '600px', padding: '20px'  }}>
                                <h1 style={{ fontSize: '55px' }}>{item.title}</h1>
                                <h4 className="mb-4">{item.message}</h4>
                                <h5 className="mb-4">{item.message1}</h5>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h1 style={{ fontSize: '30px' }}>{item.parintii_nume1}</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h1 style={{ fontSize: '30px' }}>{item.parintii_nume2}</h1>
                                    </div>
                                </div>
                                <FavoriteIcon sx={{ color: red[800], fontSize: 40 }} />
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{item.nasii}</h4>
                                <h1 style={{ fontSize: '30px' }}>{item.nasii_nume}</h1>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



            <CardLocal />

            {data.blogDataConfirmare.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 style={{ fontSize: '45px' }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div className="text-center mb-5">
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>
                        
                        <h3 className="text-center " style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.mireasa} & {item.mire}
                        </h3>
                        <img  id="svg" src={flowers} style={{width: '150px'}}/>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;